import React from "react";
import {CareerMidLayout} from "../../../organisms/careers/CareerMidLayout";
import {WhySuprieveContent} from "../../../organisms/careers/WhySuprieveContent";
import {graphql} from "gatsby";
import {WhySuprieveHeader} from "../../../organisms/menus/careers/new/WhySuprieveHeader";

export default ({data}) => <CareerMidLayout current="why-suprieve" extraHeaders={<WhySuprieveHeader />}>
  <WhySuprieveContent data={data} />
</CareerMidLayout>;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativePath: {regex: "/career_whysuprieve/workplace/"}}
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
    }
  }
`;